const app = {
  state: {
    globalLoading: false, // 全局 loading
    btnLoading: false // 按钮 loading
  },
  mutations: {
    SET_GLOBAL_LOADING: (state, status) => {
      // error 直接重置 status
      if (status === 'error') {
        state.globalLoading = false
      }
      state.globalLoading = status
    },
    SET_BTN_LOADING: (state, status) => {
      // error 直接重置 status
      if (status === 'error') {
        state.btnLoading = false
      }
      state.btnLoading = status
    }
  },
  actions: {
    setGlobalLoading ({ commit }, status) {
      commit('SET_GLOBAL_LOADING', status)
    },
    setBtnLoading ({ commit }, status) {
      commit('SET_BTN_LOADING', status)
    }
  }
}

export default app
